@charset "UTF-8";
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans KR Thin"), local("NotoSansKR-Thin"), url("../assets/basic/font/NotoSansKR-Thin.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Thin.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-200 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 200;
  src: local("Noto Sans KR Light"), local("NotoSansKR-Light"), url("../assets/basic/font/NotoSansKR-Light.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-300 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans KR DemiLight"), local("NotoSansKR-DemiLight"), url("../assets/basic/font/NotoSansKR-DemiLight.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-DemiLight.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-regular - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("../assets/basic/font/NotoSansKR-Regular.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("../assets/basic/font/NotoSansKR-Medium.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("../assets/basic/font/NotoSansKR-Bold.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-900 - latin */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans KR Black"), local("NotoSansKR-Black"), url("../assets/basic/font/NotoSansKR-Black.woff2") format("woff2"), url("../assets/basic/font/NotoSansKR-Black.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/** Ionic CSS Variables **/
:root {
  --ion-background-color: #f1f6f8;
  --ion-background-color-rgb: 241, 246, 248;
  --ion-text-color: #445760;
  --ion-text-color-rgb: 68, 87, 96;
  --ion-color-step-50: #e8eef0;
  --ion-color-step-100: #e0e6e9;
  --ion-color-step-150: #d7dee1;
  --ion-color-step-200: #ced6da;
  --ion-color-step-250: #c6ced2;
  --ion-color-step-300: #bdc6ca;
  --ion-color-step-350: #b4bec3;
  --ion-color-step-400: #acb6bb;
  --ion-color-step-450: #a3aeb4;
  --ion-color-step-500: #9ba7ac;
  --ion-color-step-550: #929fa4;
  --ion-color-step-600: #89979d;
  --ion-color-step-650: #818f95;
  --ion-color-step-700: #78878e;
  --ion-color-step-750: #6f7f86;
  --ion-color-step-800: #67777e;
  --ion-color-step-850: #5e6f77;
  --ion-color-step-900: #55676f;
  --ion-color-primary: #44C650;
  --ion-color-primary-rgb: 68, 198, 80;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3db248;
  --ion-color-primary-tint: #daf4dc;
  --ion-color-primary-translucent: #daf4dc;
  --ion-color-primary-translucent-rgb: 218, 244, 220;
  --ion-color-secondary: #D1F6E3;
  --ion-color-secondary-rgb: 209, 246, 227;
  --ion-color-secondary-contrast: #2F8D38;
  --ion-color-secondary-contrast-rgb: 47, 141, 56;
  --ion-color-secondary-shade: #bcddcc;
  --ion-color-secondary-tint: #f6fdf9;
  --ion-color-secondary-translucent: #f6fdf9;
  --ion-color-secondary-translucent-rgb: 246, 253, 249;
  --ion-color-tertiary: #25A485;
  --ion-color-tertiary-rgb: 37, 164, 133;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #219478;
  --ion-color-tertiary-tint: #d3ede7;
  --ion-color-tertiary-translucent: #d3ede7;
  --ion-color-tertiary-translucent-rgb: 211, 237, 231;
  --ion-color-fourth: #357F6D;
  --ion-color-fourth-rgb: 53, 127, 109;
  --ion-color-fourth-contrast: #ffffff;
  --ion-color-fourth-contrast-rgb: 255, 255, 255;
  --ion-color-fourth-shade: #307262;
  --ion-color-fourth-tint: #d7e5e2;
  --ion-color-fourth-translucent: #d7e5e2;
  --ion-color-fourth-translucent-rgb: 215, 229, 226;
  --ion-color-success: #46BDE2;
  --ion-color-success-rgb: 70, 189, 226;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #3faacb;
  --ion-color-success-tint: #daf2f9;
  --ion-color-success-translucent: #daf2f9;
  --ion-color-success-translucent-rgb: 218, 242, 249;
  --ion-color-warning: #ff9041;
  --ion-color-warning-rgb: 255, 144, 65;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e6823b;
  --ion-color-warning-tint: #ffe9d9;
  --ion-color-warning-translucent: #ffe9d9;
  --ion-color-warning-translucent-rgb: 255, 233, 217;
  --ion-color-danger: #FF6060;
  --ion-color-danger-rgb: 255, 96, 96;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e65656;
  --ion-color-danger-tint: #ffdfdf;
  --ion-color-danger-translucent: #ffdfdf;
  --ion-color-danger-translucent-rgb: 255, 223, 223;
  --ion-color-light: #F7F9FC;
  --ion-color-light-rgb: 247, 249, 252;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dee0e3;
  --ion-color-light-tint: #fdfefe;
  --ion-color-light-translucent: #fdfefe;
  --ion-color-light-translucent-rgb: 253, 254, 254;
  --ion-color-medium: #9CB3BE;
  --ion-color-medium-rgb: 156, 179, 190;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #8ca1ab;
  --ion-color-medium-tint: #ebf0f2;
  --ion-color-medium-translucent: #ebf0f2;
  --ion-color-medium-translucent-rgb: 235, 240, 242;
  --ion-color-dark: #445760;
  --ion-color-dark-rgb: 68, 87, 96;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #3d4e56;
  --ion-color-dark-tint: #dadddf;
  --ion-color-dark-translucent: #dadddf;
  --ion-color-dark-translucent-rgb: 218, 221, 223;
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e6e6e6;
  --ion-color-white-tint: white;
  --ion-color-white-translucent: white;
  --ion-color-white-translucent-rgb: 255, 255, 255;
  --ion-color-star: #ffba0d;
  --ion-color-star-rgb: 255, 186, 13;
  --ion-color-star-contrast: #ffffff;
  --ion-color-star-contrast-rgb: 255, 255, 255;
  --ion-color-star-shade: #e6a70c;
  --ion-color-star-tint: #fff1cf;
  --ion-color-star-translucent: #fff1cf;
  --ion-color-star-translucent-rgb: 255, 241, 207;
  --ion-color-monitor-warning: #FFC876;
  --ion-color-monitor-warning-rgb: 255, 200, 118;
  --ion-color-monitor-warning-contrast: #ffffff;
  --ion-color-monitor-warning-contrast-rgb: 255, 255, 255;
  --ion-color-monitor-warning-shade: #e6b46a;
  --ion-color-monitor-warning-tint: #fff4e4;
  --ion-color-monitor-warning-translucent: #fff4e4;
  --ion-color-monitor-warning-translucent-rgb: 255, 244, 228;
  --ion-color-monitor-yellow: #FCE640;
  --ion-color-monitor-yellow-rgb: 252, 230, 64;
  --ion-color-monitor-yellow-contrast: #ffffff;
  --ion-color-monitor-yellow-contrast-rgb: 255, 255, 255;
  --ion-color-monitor-yellow-shade: #e3cf3a;
  --ion-color-monitor-yellow-tint: #fefad9;
  --ion-color-monitor-yellow-translucent: #fefad9;
  --ion-color-monitor-yellow-translucent-rgb: 254, 250, 217;
  --ion-color-monitor-green: #B9DC3D;
  --ion-color-monitor-green-rgb: 185, 220, 61;
  --ion-color-monitor-green-contrast: #ffffff;
  --ion-color-monitor-green-contrast-rgb: 255, 255, 255;
  --ion-color-monitor-green-shade: #a7c637;
  --ion-color-monitor-green-tint: #f1f8d8;
  --ion-color-monitor-green-translucent: #f1f8d8;
  --ion-color-monitor-green-translucent-rgb: 241, 248, 216;
  --ion-color-main-btn-grey: #969696;
  --ion-color-main-btn-grey-rgb: 150, 150, 150;
  --ion-color-main-btn-grey-contrast: #ffffff;
  --ion-color-main-btn-grey-contrast-rgb: 255, 255, 255;
  --ion-color-main-btn-grey-shade: #878787;
  --ion-color-main-btn-grey-tint: #eaeaea;
  --ion-color-main-btn-grey-translucent: #eaeaea;
  --ion-color-main-btn-grey-translucent-rgb: 234, 234, 234;
}

.ion-color-primary {
  --ion-color-base: var(--ion-color-primary);
  --ion-color-base-rgb: var(--ion-color-primary-rgb);
  --ion-color-contrast: var(--ion-color-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-shade);
  --ion-color-tint: var(--ion-color-primary-tint);
  --ion-color-translucent: var(--ion-color-primary-translucent);
}

.ion-color-secondary {
  --ion-color-base: var(--ion-color-secondary);
  --ion-color-base-rgb: var(--ion-color-secondary-rgb);
  --ion-color-contrast: var(--ion-color-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-shade);
  --ion-color-tint: var(--ion-color-secondary-tint);
  --ion-color-translucent: var(--ion-color-secondary-translucent);
}

.ion-color-tertiary {
  --ion-color-base: var(--ion-color-tertiary);
  --ion-color-base-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary-shade);
  --ion-color-tint: var(--ion-color-tertiary-tint);
  --ion-color-translucent: var(--ion-color-tertiary-translucent);
}

.ion-color-success {
  --ion-color-base: var(--ion-color-success);
  --ion-color-base-rgb: var(--ion-color-success-rgb);
  --ion-color-contrast: var(--ion-color-success-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-shade);
  --ion-color-tint: var(--ion-color-success-tint);
  --ion-color-translucent: var(--ion-color-success-translucent);
}

.ion-color-warning {
  --ion-color-base: var(--ion-color-warning);
  --ion-color-base-rgb: var(--ion-color-warning-rgb);
  --ion-color-contrast: var(--ion-color-warning-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-shade);
  --ion-color-tint: var(--ion-color-warning-tint);
  --ion-color-translucent: var(--ion-color-warning-translucent);
}

.ion-color-danger {
  --ion-color-base: var(--ion-color-danger);
  --ion-color-base-rgb: var(--ion-color-danger-rgb);
  --ion-color-contrast: var(--ion-color-danger-contrast);
  --ion-color-contrast-rgb: var(--ion-color-danger-contrast-rgb);
  --ion-color-shade: var(--ion-color-danger-shade);
  --ion-color-tint: var(--ion-color-danger-tint);
  --ion-color-translucent: var(--ion-color-danger-translucent);
}

.ion-color-light {
  --ion-color-base: var(--ion-color-light);
  --ion-color-base-rgb: var(--ion-color-light-rgb);
  --ion-color-contrast: var(--ion-color-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-shade);
  --ion-color-tint: var(--ion-color-light-tint);
  --ion-color-translucent: var(--ion-color-light-translucent);
}

.ion-color-medium {
  --ion-color-base: var(--ion-color-medium);
  --ion-color-base-rgb: var(--ion-color-medium-rgb);
  --ion-color-contrast: var(--ion-color-medium-contrast);
  --ion-color-contrast-rgb: var(--ion-color-medium-contrast-rgb);
  --ion-color-shade: var(--ion-color-medium-shade);
  --ion-color-tint: var(--ion-color-medium-tint);
  --ion-color-translucent: var(--ion-color-medium-translucent);
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
  --ion-color-translucent: var(--ion-color-dark-translucent);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
  --ion-color-translucent: var(--ion-color-white-translucent);
}

.ion-color-star {
  --ion-color-base: var(--ion-color-star);
  --ion-color-base-rgb: var(--ion-color-star-rgb);
  --ion-color-contrast: var(--ion-color-star-contrast);
  --ion-color-contrast-rgb: var(--ion-color-star-contrast-rgb);
  --ion-color-shade: var(--ion-color-star-shade);
  --ion-color-tint: var(--ion-color-star-tint);
  --ion-color-translucent: var(--ion-color-star-translucent);
}

.ion-color-main-btn-grey {
  --ion-color-base: var(--ion-color-main-btn-grey);
  --ion-color-base-rgb: var(--ion-color-main-btn-grey-rgb);
  --ion-color-contrast: var(--ion-color-main-btn-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-main-btn-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-main-btn-grey-shade);
  --ion-color-tint: var(--ion-color-main-btn-grey-tint);
  --ion-color-translucent: var(--ion-color-main-btn-grey-translucent);
}

@media (prefers-color-scheme: dark) {
  body {
    --ion-background-color: #222120;
    --ion-background-color-rgb: 34, 33, 32;
    --ion-text-color: #f1f0ef;
    --ion-text-color-rgb: 241, 240, 239;
    --ion-color-step-50: #2c2b2a;
    --ion-color-step-100: #373635;
    --ion-color-step-150: #41403f;
    --ion-color-step-200: #4b4a49;
    --ion-color-step-250: #565554;
    --ion-color-step-300: #605f5e;
    --ion-color-step-350: #6a6968;
    --ion-color-step-400: #757473;
    --ion-color-step-450: #7f7e7d;
    --ion-color-step-500: #8a8988;
    --ion-color-step-550: #949392;
    --ion-color-step-600: #9e9d9c;
    --ion-color-step-650: #a9a8a7;
    --ion-color-step-700: #b3b2b1;
    --ion-color-step-750: #bdbcbb;
    --ion-color-step-800: #c8c7c6;
    --ion-color-step-850: #d2d1d0;
    --ion-color-step-900: #dcdbda;
    --ion-color-primary: #44C650;
    --ion-color-primary-rgb: 68, 198, 80;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3db248;
    --ion-color-primary-tint: #daf4dc;
    --ion-color-primary-translucent: #daf4dc;
    --ion-color-primary-translucent-rgb: 218, 244, 220;
    --ion-color-secondary: #D1F6E3;
    --ion-color-secondary-rgb: 209, 246, 227;
    --ion-color-secondary-contrast: #2F8D38;
    --ion-color-secondary-contrast-rgb: 47, 141, 56;
    --ion-color-secondary-shade: #bcddcc;
    --ion-color-secondary-tint: #f6fdf9;
    --ion-color-secondary-translucent: #f6fdf9;
    --ion-color-secondary-translucent-rgb: 246, 253, 249;
    --ion-color-tertiary: #25A485;
    --ion-color-tertiary-rgb: 37, 164, 133;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #219478;
    --ion-color-tertiary-tint: #d3ede7;
    --ion-color-tertiary-translucent: #d3ede7;
    --ion-color-tertiary-translucent-rgb: 211, 237, 231;
    --ion-color-success: #25A485;
    --ion-color-success-rgb: 37, 164, 133;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #219478;
    --ion-color-success-tint: #d3ede7;
    --ion-color-success-translucent: #d3ede7;
    --ion-color-success-translucent-rgb: 211, 237, 231;
    --ion-color-warning: #ff9041;
    --ion-color-warning-rgb: 255, 144, 65;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e6823b;
    --ion-color-warning-tint: #ffe9d9;
    --ion-color-warning-translucent: #ffe9d9;
    --ion-color-warning-translucent-rgb: 255, 233, 217;
    --ion-color-danger: #FF6060;
    --ion-color-danger-rgb: 255, 96, 96;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e65656;
    --ion-color-danger-tint: #ffdfdf;
    --ion-color-danger-translucent: #ffdfdf;
    --ion-color-danger-translucent-rgb: 255, 223, 223;
    --ion-color-dark: #F7F9FC;
    --ion-color-dark-rgb: 247, 249, 252;
    --ion-color-dark-contrast: #222120;
    --ion-color-dark-contrast-rgb: 34, 33, 32;
    --ion-color-dark-shade: #dee0e3;
    --ion-color-dark-tint: #fdfefe;
    --ion-color-dark-translucent: #fdfefe;
    --ion-color-dark-translucent-rgb: 253, 254, 254;
    --ion-color-medium: #9CB3BE;
    --ion-color-medium-rgb: 156, 179, 190;
    --ion-color-medium-contrast: #222120;
    --ion-color-medium-contrast-rgb: 34, 33, 32;
    --ion-color-medium-shade: #8ca1ab;
    --ion-color-medium-tint: #ebf0f2;
    --ion-color-medium-translucent: #ebf0f2;
    --ion-color-medium-translucent-rgb: 235, 240, 242;
    --ion-color-light: #445760;
    --ion-color-light-rgb: 68, 87, 96;
    --ion-color-light-contrast: #222120;
    --ion-color-light-contrast-rgb: 34, 33, 32;
    --ion-color-light-shade: #3d4e56;
    --ion-color-light-tint: #dadddf;
    --ion-color-light-translucent: #dadddf;
    --ion-color-light-translucent-rgb: 218, 221, 223;
    --ion-color-white: #181818;
    --ion-color-white-rgb: 24, 24, 24;
    --ion-color-white-contrast: white;
    --ion-color-white-contrast-rgb: 255, 255, 255;
    --ion-color-white-shade: #161616;
    --ion-color-white-tint: #d1d1d1;
    --ion-color-white-translucent: #d1d1d1;
    --ion-color-white-translucent-rgb: 209, 209, 209;
    --ion-color-star: #ffba0d;
    --ion-color-star-rgb: 255, 186, 13;
    --ion-color-star-contrast: #ffffff;
    --ion-color-star-contrast-rgb: 255, 255, 255;
    --ion-color-star-shade: #e6a70c;
    --ion-color-star-tint: #fff1cf;
    --ion-color-star-translucent: #fff1cf;
    --ion-color-star-translucent-rgb: 255, 241, 207;
  }
}

/**********************/
/** custom variables **/
/**********************/
:root {
  --ion-font-family: "Noto Sans KR", -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
  /** font size 정의 */
  --font-size-h1: 54px;
  --font-size-h2: 36px;
  --font-size-h3: 28px;
  --font-size-h4: 18px;
  --font-size-h5: 14px;
  --font-size-h6: 13px;
  --font-size-h6-small: 11px;
  /** input은 보통 앱이나 웹 둘다 16px을 많이 쓴다 */
  --font-size-input: 15px;
  /** app-col 등 element 간의 margin/padding 값 */
  --app-padding: 6px;
  /** card 여백 등의 margin/padding 값 */
  --app-margin: 12px;
  /** 페이지 여백 등의 margin/padding 값 */
  --app-page-padding: 16px;
  /** input, button, card의 border radius */
  --app-border-radius: 3px;
  /** input, button, card의 box shadow */
  --app-box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  /** placeholder color */
  --app-placeholder-color: var(--ion-color-step-800);
}

@media screen and (max-width: 768px) {
  body {
    --font-size-h1: 30px;
    --font-size-h2: 24px;
    --font-size-h3: 20px;
    --font-size-h4: 17px;
    --font-size-h5: 14px;
    --font-size-h6: 13px;
    --font-size-h6-small: 11px;
    --font-size-input: 15px;
    /** app-col 등 element 간의 margin/padding 값 */
    --app-padding: 4px;
    /** card 여백 등의 margin/padding 값 */
    --app-margin: 10px;
    /** 페이지 여백 등의 margin/padding 값 */
    --app-page-padding: 16px;
  }
}
