@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 100;
src: local('Noto Sans KR Thin'), local('NotoSansKR-Thin'),
        url('../assets/basic/font/NotoSansKR-Thin.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Thin.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-200 - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 200;
src: local('Noto Sans KR Light'), local('NotoSansKR-Light'),
        url('../assets/basic/font/NotoSansKR-Light.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Light.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-300 - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 300;
src: local('Noto Sans KR DemiLight'), local('NotoSansKR-DemiLight'),
        url('../assets/basic/font/NotoSansKR-DemiLight.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-DemiLight.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-regular - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 400;
src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'),
        url('../assets/basic/font/NotoSansKR-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-500 - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 500;
src: local('Noto Sans KR Medium'), local('NotoSansKR-Medium'),
        url('../assets/basic/font/NotoSansKR-Medium.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Medium.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-700 - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 700;
src: local('Noto Sans KR Bold'), local('NotoSansKR-Bold'),
        url('../assets/basic/font/NotoSansKR-Bold.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Bold.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-kr-900 - latin */
@font-face {
font-family: 'Noto Sans KR';
font-style: normal;
font-weight: 900;
src: local('Noto Sans KR Black'), local('NotoSansKR-Black'),
        url('../assets/basic/font/NotoSansKR-Black.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../assets/basic/font/NotoSansKR-Black.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}