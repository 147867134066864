html.ios {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}

html.md {
  --ion-default-font: "Roboto", "Helvetica Neue", sans-serif;
}

html {
  --ion-font-family: var(--ion-default-font);
}

body {
  background: var(--ion-background-color);
}

body.backdrop-no-scroll {
  overflow: hidden;
}

html.ios ion-modal.modal-card .ion-page > ion-header > ion-toolbar:first-of-type {
  padding-top: 0px;
}

html.ios ion-modal .ion-page {
  border-radius: inherit;
}

.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  contain: layout size style;
  overflow: hidden;
  z-index: 0;
}

.split-pane-visible > .ion-page.split-pane-main {
  position: relative;
}

ion-route,
ion-route-redirect,
ion-router,
ion-select-option,
ion-nav-controller,
ion-menu-controller,
ion-action-sheet-controller,
ion-alert-controller,
ion-loading-controller,
ion-modal-controller,
ion-picker-controller,
ion-popover-controller,
ion-toast-controller,
.ion-page-hidden,
[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

.ion-page-invisible {
  opacity: 0;
}

.can-go-back > ion-header ion-back-button {
  display: block;
}

html.plt-ios.plt-hybrid, html.plt-ios.plt-pwa {
  --ion-statusbar-padding: 20px;
}

@supports (padding-top: 20px) {
  html {
    --ion-safe-area-top: var(--ion-statusbar-padding);
  }
}
@supports (padding-top: constant(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: constant(safe-area-inset-top);
    --ion-safe-area-bottom: constant(safe-area-inset-bottom);
    --ion-safe-area-left: constant(safe-area-inset-left);
    --ion-safe-area-right: constant(safe-area-inset-right);
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  html {
    --ion-safe-area-top: env(safe-area-inset-top);
    --ion-safe-area-bottom: env(safe-area-inset-bottom);
    --ion-safe-area-left: env(safe-area-inset-left);
    --ion-safe-area-right: env(safe-area-inset-right);
  }
}
ion-card.ion-color .ion-inherit-color,
ion-card-header.ion-color .ion-inherit-color {
  color: inherit;
}

.menu-content {
  transform: translate3d(0,  0,  0);
}

.menu-content-open {
  cursor: pointer;
  touch-action: manipulation;
  pointer-events: none;
}

.ios .menu-content-reveal {
  box-shadow: -8px 0 42px rgba(0, 0, 0, 0.08);
}

[dir=rtl].ios .menu-content-reveal {
  box-shadow: 8px 0 42px rgba(0, 0, 0, 0.08);
}

.md .menu-content-reveal {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

.md .menu-content-push {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.18);
}

/*# sourceMappingURL=core.css.map */
